import { GetStaticProps } from "next";
import { useRouter } from "next/router";

import { fetchPageBySlug } from "@clients/contentfulClient";
import Meta from "@components/Meta/meta";
import Layout from "@containers/Layout/layout";
import { socialMediaDefaultImage } from "@utils/imageHelpers/imageHelper";
import { getFirstParentPageLink } from "@utils/layoutHelpers/layoutHelper";
import { pathToSlugs } from "@utils/path";
import { getBaseUrl, replaceImageVaultBasePath } from "@utils/urlResolver";

import { UrlStructureService } from "services/UrlStructureService";
import { getLayoutData } from "services/layoutService";

import ErrorPage from "../containers/ErrorPage";

export default function NotFound({ page, urlStructure, layoutData }) {
  const router = useRouter();
  const slugs = pathToSlugs(router.asPath);
  const seo = page?.slug?.seo;
  const firstParentPage = getFirstParentPageLink(router.asPath, slugs, urlStructure);
  const base = getBaseUrl();
  const metaImage =
    base +
    (replaceImageVaultBasePath(seo?.ivSocialMediaImage?.[0]?.socialMediaImageVersion, false) ||
      socialMediaDefaultImage?.url);

  return (
    <Layout {...layoutData} is404={true}>
      <Meta
        title={seo?.title}
        description={seo?.description}
        image={metaImage}
        imageAlt={
          seo?.ivSocialMediaImage?.[0]?.Metadata?.[0]?.Value ||
          socialMediaDefaultImage?.imageAltText
        }
        keywords={seo?.keywords?.map((i: string) => i)}
        canonicalUrl={seo?.canonicalUrl}
        url={base + "/404"}
        linkedFaqs={seo?.linkedFaqs}
        noIndex={seo?.noIndex}
        noFollow={seo?.noFollow}
      />
      <ErrorPage pageData={page?.slug?.data} errorCode={404} firstParentPage={firstParentPage} />
    </Layout>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const layoutData = await getLayoutData("/sidan-hittades-inte", context.preview ?? false, false);
  const urlStructure = await UrlStructureService();
  const page = await fetchPageBySlug("sidan-hittades-inte", 1, context.preview ?? false);
  return {
    props: {
      page,
      urlStructure,
      layoutData,
    },
    revalidate: 60,
  };
};
